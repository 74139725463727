















































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import UserAvatar from "@/components/user/UserAvatar.vue";

@Component({
  components: {UserAvatar},
})
export default class FilterDropdown extends Vue {
  currentFilteredUser: any = null;

  allowUserFiltering: boolean = true;

  showMembers: boolean = this.objectiveDataMembers;
  showStartDate: boolean = this.objectiveDataStartDate;
  showDueDate: boolean = this.objectiveDataDueDate;
  showCompleted: boolean = this.objectiveShowCompleted;
  showStatus: boolean = this.objectiveShowStatus;

  sortType: string = this.objectiveSortType;
  isDropdownOpen: boolean = false;

  get workspace() {
    return this.$store.state.workspace;
  }
  get board() {
    return this.$store.state.board;
  }

  get filteredMembers() {

    if(this.workspace.members && !this.$store.state.board) {
      return this.workspace.members.filter((member: any) => {
        return member.user.deleted_at === null;
      })
    } else if (this.$store.state.board) {
      return this.board.members.filter((member: any)=>member.user.deleted_at === null);
    }
    return null;
  }

  get objectiveDataMembers() {
    return this.$store.state.objective_data_members;
  }

  get objectiveDataDueDate() {
    return this.$store.state.objective_data_due_date;
  }

  get objectiveDataStartDate() {
    return this.$store.state.objective_data_start_date;
  }

  get objectiveShowCompleted() {
    return this.$store.state.objective_data_completed_activities;
  }

  get objectiveShowStatus() {
    return this.$store.state.objective_data_status;
  }

  get objectiveSortType() {
    return this.$store.state.objective_sort_type
  }


  toggleShowDueDate() {
    this.$store.commit('set_objective_data_due_date', !this.objectiveDataDueDate);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  toggleShowStartDate() {
    this.$store.commit('set_objective_data_start_date', !this.objectiveDataStartDate);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  toggleShowMembers() {
    this.$store.commit('set_objective_data_members', !this.objectiveDataMembers);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  toggleShowCompleted() {
    this.$store.commit('set_objective_data_completed_activities', !this.objectiveShowCompleted);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  toggleShowStatus() {
    this.$store.commit('set_objective_data_status', !this.objectiveShowStatus);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  setSelectedUser(user: any) {
    this.currentFilteredUser = user;
    if(user) {
      this.$emit('selected-user', user.id);
    } else {

      this.currentFilteredUser = null;
      this.$emit('selected-user', 'all');
    }
  }

  // setSortByDate(dateType: string) {
  //   this.$events.fire('sort_by_date_master_dashboard', dateType)
  //   this.$store.commit('set_objective_sort_type', dateType);
  // }

  setSortByType(dateType: string) {
    this.sortType = dateType;
    this.$events.fire('sort_by_date_master_dashboard', dateType);
    this.$store.commit('set_objective_sort_type', dateType);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;  // Toggle dropdown open/close
  }

  closeDropdown() {
    this.isDropdownOpen = false;  // Force close the dropdown
  }


  mounted() {
    if(this.objectiveSortType === 'order') {
      this.sortType = 'order';
    }
  }

  @Watch('showMembers')
  onShowMembersChange(newValue: boolean) {
    this.$store.commit('set_objective_data_members', newValue);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  @Watch('showStartDate')
  onShowStartDateChange(newValue: boolean) {
    this.$store.commit('set_objective_data_start_date', newValue);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  @Watch('showDueDate')
  onShowDueDateChange(newValue: boolean) {
    this.$store.commit('set_objective_data_due_date', newValue);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  @Watch('showCompleted')
  onShowCompletedChange(newValue: boolean) {
    this.$store.commit('set_objective_data_completed_activities', newValue);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  @Watch('showStatus')
  onShowStatusChange(newValue: boolean) {
    this.$store.commit('set_objective_data_status', newValue);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  @Watch('sortType')
  onSortTypeChange(newValue: string) {
    this.$store.commit('set_objective_sort_type', newValue);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
   


  }
}
